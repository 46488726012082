body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.root {
  background-color: #ebe9e6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Mosk';
  src: local('Mosk'), url(./fonts/Mosk-Ultra-Bold-900.ttf) format('truetype');
}

@font-face {
  font-family: 'LeagueGothic';
  src: local('LeagueGothic'), url(./fonts/LeagueGothic-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Abel';
  src: local('Abel'), url(./fonts/Abel-Regular.ttf) format('truetype');
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(88, 88, 88);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(27, 27, 27);
}

.container {
  display: block;
  height: 900px;
  width: 1530px;
  justify-content: center;
  padding-top: 2px;
}

.row {
  max-height: 100%;
}