.TakeOffPage {
  height: 100%;
  width: 100%;
  background: transparent;
  text-align: center;
}

.row.pageBody {
  height: 91%;
}

.row.pageHeader {
  height: 8%;
}

.col {
  height: 100%;
}

.col.takeoff {
  min-width: 60%;
}

.col.pricing {
  min-width: 40%;
  min-height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.row {
  height: 100%;
}